import { GlobeAltIcon, MegaphoneIcon, UserGroupIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';
import { useRouter } from 'next/router'
import { useCallback, useState } from 'react';
import { mutate } from 'swr';
import useCurrentAccount from '../context/useCurrentAccount';

import logoWithText from '../../public/logo-transparent-png-small.png'
import Image from 'next/image';

const navigation = [
    {
        name: 'Monitors',
        href: '/dashboard',
        show_down_counter: true,
        icon: <GlobeAltIcon className='h-6 w-6' />
    },
    {
        name: 'Contacts',
        href: '/dashboard/contacts',
        icon: <UserGroupIcon className='h-6 w-6' />
    },
    {
        name: 'Status Pages',
        href: '/dashboard/status_pages',
        icon: <MegaphoneIcon className='h-6 w-6' />
    },
]

function MobileMenuItem({ activePath, href, children }: any) {
    return (
        <li>
            <Link className={`flex justify-between items-center px-3 py-1 ${activePath == href ? 'text-white' : 'text-gray-300'}`} href={href}>
                {children}
            </Link>
        </li>
    )
}

export default function AsideNavigation({ activePath, children }: { activePath: string, children: any }) {
    const router = useRouter();
    // const activePath = router.pathname;

    const [mobileMenuOpened, setMobileMenuOpened] = useState(false)

    const { account } = useCurrentAccount({ requireLoggedIn: true });

    const logoutUser = useCallback((e: any) => {
        e.preventDefault();

        fetch('/web_api/accounts/logout',
            {
                method: 'POST',
            })
            .then(resp => resp.json())
            .then(json => {
                // console.log({ json });
                if (json.status == 'ok') {
                    router.push('/')
                    mutate('/web_api/accounts/current')
                    // .then(() => router.push('/'))

                }
            }).catch(e => {
                console.error(e)
            })
    }, [router]);
    return (
        <div className='bg-slate-800'>
            <nav className="lg:hidden flex items-center justify-between p-4 md:p-8 bg-slate-700 mb-3">
                <div className="w-full xl:w-auto px-2 xl:mr-12">
                    <div className="flex items-center justify-between">
                        <span className="inline-flex items-center h-8">
                            <Image src={logoWithText} className='h-10' width={198} priority height={40} alt='Uptime Monitor' />
                        </span>
                        <div className="xl:hidden">
                            <button className="navbar-burger text-gray-400 hover:text-gray-300 focus:outline-none" onClick={() => setMobileMenuOpened(!mobileMenuOpened)}>
                                <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <title>Mobile menu</title>
                                    <path d="M1 2H19C19.2652 2 19.5196 1.89464 19.7071 1.70711C19.8946 1.51957 20 1.26522 20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM19 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H19C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10ZM19 5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H19C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5Z" fill="currentColor"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </nav>

            <ul className={`${mobileMenuOpened ? '' : 'hidden'} text-white lg:hidden bg-slate-900 -mt-3 py-3 pr-6`}>
                {navigation.map(n =>
                    <MobileMenuItem key={n.href} activePath={activePath} href={n.href}>
                        <div className='flex items-center w-full justify-end'>
                            <span className="text-gray-400">
                                {n.icon}
                            </span>
                            <span className="ml-3 text-sm font-semibold">{n.name}</span>
                        </div>
                        {
                            n.show_down_counter && account?.down_count > 0 &&
                            <span className="ml-2 flex items-center justify-center w-6 h-6 bg-red-500 text-white text-xs font-semibold rounded-full">
                                {account?.down_count}
                            </span>
                        }
                    </MobileMenuItem>
                )}

                <div className='py-1'>&nbsp;</div>

                <MobileMenuItem activePath={activePath} href={'/dashboard/billing'}>
                    <div className='flex items-center w-full justify-end'>
                        <span className="text-gray-400">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                            </svg>
                        </span>
                        <span className="ml-4 text-sm font-semibold">Billing</span>
                    </div>
                </MobileMenuItem>

                <MobileMenuItem activePath={activePath} href={'/dashboard/settings'}>
                    <div className='flex items-center w-full justify-end'>
                        <span className="text-gray-400">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.46458 3.95725C9.85508 2.34758 12.1449 2.34758 12.5354 3.95725C12.594 4.19907 12.7089 4.42363 12.8707 4.61267C13.0324 4.8017 13.2366 4.94987 13.4664 5.0451C13.6963 5.14033 13.9454 5.17995 14.1935 5.16071C14.4415 5.14148 14.6816 5.06394 14.894 4.93442C16.3084 4.07275 17.9282 5.69158 17.0665 7.10692C16.9372 7.31927 16.8597 7.55914 16.8406 7.80704C16.8214 8.05494 16.8609 8.30387 16.9561 8.5336C17.0512 8.76332 17.1992 8.96736 17.388 9.12913C17.5768 9.2909 17.8012 9.40583 18.0428 9.46458C19.6524 9.85508 19.6524 12.1449 18.0428 12.5354C17.8009 12.594 17.5764 12.7089 17.3873 12.8707C17.1983 13.0324 17.0501 13.2366 16.9549 13.4664C16.8597 13.6963 16.8201 13.9454 16.8393 14.1935C16.8585 14.4415 16.9361 14.6816 17.0656 14.894C17.9273 16.3084 16.3084 17.9282 14.8931 17.0665C14.6807 16.9372 14.4409 16.8597 14.193 16.8406C13.9451 16.8214 13.6961 16.8609 13.4664 16.9561C13.2367 17.0512 13.0326 17.1992 12.8709 17.388C12.7091 17.5768 12.5942 17.8012 12.5354 18.0428C12.1449 19.6524 9.85508 19.6524 9.46458 18.0428C9.40599 17.8009 9.29113 17.5764 9.12935 17.3873C8.96757 17.1983 8.76344 17.0501 8.53357 16.9549C8.3037 16.8597 8.0546 16.8201 7.80653 16.8393C7.55846 16.8585 7.31844 16.9361 7.106 17.0656C5.69158 17.9273 4.07183 16.3084 4.9335 14.8931C5.06284 14.6807 5.14025 14.4409 5.15944 14.193C5.17863 13.9451 5.13906 13.6961 5.04393 13.4664C4.94881 13.2367 4.80082 13.0326 4.612 12.8709C4.42318 12.7091 4.19885 12.5942 3.95725 12.5354C2.34758 12.1449 2.34758 9.85508 3.95725 9.46458C4.19907 9.40599 4.42363 9.29113 4.61267 9.12935C4.8017 8.96757 4.94987 8.76344 5.0451 8.53357C5.14033 8.3037 5.17995 8.0546 5.16071 7.80653C5.14148 7.55846 5.06394 7.31844 4.93442 7.106C4.07275 5.69158 5.69158 4.07183 7.10692 4.9335C8.02358 5.49083 9.21158 4.99767 9.46458 3.95725Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="ml-4 text-sm font-semibold">Settings</span>
                    </div>
                </MobileMenuItem>

                <MobileMenuItem activePath={activePath} href={'/dashboard/settings'}>
                    <div className='flex items-center w-full justify-end' onClick={logoutUser}>
                        <span className="text-gray-400">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.8333 7.33335V5.50002C12.8333 5.01379 12.6402 4.54747 12.2964 4.20366C11.9525 3.85984 11.4862 3.66669 11 3.66669H4.58333C4.0971 3.66669 3.63079 3.85984 3.28697 4.20366C2.94315 4.54747 2.75 5.01379 2.75 5.50002V16.5C2.75 16.9863 2.94315 17.4526 3.28697 17.7964C3.63079 18.1402 4.0971 18.3334 4.58333 18.3334H11C11.4862 18.3334 11.9525 18.1402 12.2964 17.7964C12.6402 17.4526 12.8333 16.9863 12.8333 16.5V14.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M6.41675 11H19.2501M19.2501 11L16.5001 8.25M19.2501 11L16.5001 13.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                            </svg>
                        </span>
                        <span className="ml-4 text-sm font-semibold">Log Out</span>
                    </div>
                </MobileMenuItem>
            </ul>


            <div className="hidden lg:block navbar-menu relative z-40">
                <div className="lg:hidden navbar-backdrop fixed top-0 left-0 w-full h-full bg-slate-800 opacity-50"></div>
                <nav className="fixed top-0 left-0 bottom-0 w-full max-w-xs flex flex-col h-full py-8 px-4 bg-slate-700 overflow-auto">
                    <div className="mb-6">
                        <span className="inline-block mb-12">
                            <Image src={logoWithText} className='h-10' width={198} height={40} priority alt='Uptime Monitor' />
                        </span>
                        <ul>
                            {navigation.map(n =>
                                <li key={n.href} className="mb-4">
                                    <Link className={`flex justify-between items-center px-3 py-4 rounded-xl ${activePath == n.href ? 'bg-slate-500 text-white' : 'hover:bg-slate-800 text-gray-300'}`} href={n.href}>
                                        <div className='flex items-center'>
                                            <span className="text-gray-400">
                                                {n.icon}
                                            </span>
                                            <span className="ml-3 text-sm font-semibold">{n.name}</span>
                                        </div>
                                        {
                                            n.show_down_counter && account?.down_count > 0 &&
                                            <span className="flex items-center justify-center w-6 h-6 bg-red-500 text-white text-xs font-semibold rounded-full">
                                                {account?.down_count}
                                            </span>
                                        }
                                    </Link>
                                </li>
                            )}
                        </ul>
                    </div>
                    <div className="mt-auto">
                        <Link className={`flex items-center p-4 mb-4 rounded-xl ${activePath == '/dashboard/billing' ? 'bg-slate-500 text-white' : 'hover:bg-slate-800 text-gray-300'}`}
                            href='/dashboard/billing'>
                            <span className="text-gray-400">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                </svg>
                            </span>
                            <span className="ml-4 text-sm font-semibold">Billing</span>
                        </Link>

                        <Link className={`flex items-center p-4 mb-4 rounded-xl ${activePath == '/dashboard/settings' ? 'bg-slate-500 text-white' : 'hover:bg-slate-800 text-gray-300'}`}
                            href='/dashboard/settings'>
                            <span className="text-gray-400">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.46458 3.95725C9.85508 2.34758 12.1449 2.34758 12.5354 3.95725C12.594 4.19907 12.7089 4.42363 12.8707 4.61267C13.0324 4.8017 13.2366 4.94987 13.4664 5.0451C13.6963 5.14033 13.9454 5.17995 14.1935 5.16071C14.4415 5.14148 14.6816 5.06394 14.894 4.93442C16.3084 4.07275 17.9282 5.69158 17.0665 7.10692C16.9372 7.31927 16.8597 7.55914 16.8406 7.80704C16.8214 8.05494 16.8609 8.30387 16.9561 8.5336C17.0512 8.76332 17.1992 8.96736 17.388 9.12913C17.5768 9.2909 17.8012 9.40583 18.0428 9.46458C19.6524 9.85508 19.6524 12.1449 18.0428 12.5354C17.8009 12.594 17.5764 12.7089 17.3873 12.8707C17.1983 13.0324 17.0501 13.2366 16.9549 13.4664C16.8597 13.6963 16.8201 13.9454 16.8393 14.1935C16.8585 14.4415 16.9361 14.6816 17.0656 14.894C17.9273 16.3084 16.3084 17.9282 14.8931 17.0665C14.6807 16.9372 14.4409 16.8597 14.193 16.8406C13.9451 16.8214 13.6961 16.8609 13.4664 16.9561C13.2367 17.0512 13.0326 17.1992 12.8709 17.388C12.7091 17.5768 12.5942 17.8012 12.5354 18.0428C12.1449 19.6524 9.85508 19.6524 9.46458 18.0428C9.40599 17.8009 9.29113 17.5764 9.12935 17.3873C8.96757 17.1983 8.76344 17.0501 8.53357 16.9549C8.3037 16.8597 8.0546 16.8201 7.80653 16.8393C7.55846 16.8585 7.31844 16.9361 7.106 17.0656C5.69158 17.9273 4.07183 16.3084 4.9335 14.8931C5.06284 14.6807 5.14025 14.4409 5.15944 14.193C5.17863 13.9451 5.13906 13.6961 5.04393 13.4664C4.94881 13.2367 4.80082 13.0326 4.612 12.8709C4.42318 12.7091 4.19885 12.5942 3.95725 12.5354C2.34758 12.1449 2.34758 9.85508 3.95725 9.46458C4.19907 9.40599 4.42363 9.29113 4.61267 9.12935C4.8017 8.96757 4.94987 8.76344 5.0451 8.53357C5.14033 8.3037 5.17995 8.0546 5.16071 7.80653C5.14148 7.55846 5.06394 7.31844 4.93442 7.106C4.07275 5.69158 5.69158 4.07183 7.10692 4.9335C8.02358 5.49083 9.21158 4.99767 9.46458 3.95725Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M11 13.75C12.5188 13.75 13.75 12.5188 13.75 11C13.75 9.48122 12.5188 8.25 11 8.25C9.48122 8.25 8.25 9.48122 8.25 11C8.25 12.5188 9.48122 13.75 11 13.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </span>
                            <span className="ml-4 text-sm font-semibold">Settings</span>
                        </Link>

                        <a className="flex items-center p-4 text-gray-300 hover:bg-slate-800 rounded-xl" href="#logout" onClick={logoutUser}>
                            <span className="text-gray-400">
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.8333 7.33335V5.50002C12.8333 5.01379 12.6402 4.54747 12.2964 4.20366C11.9525 3.85984 11.4862 3.66669 11 3.66669H4.58333C4.0971 3.66669 3.63079 3.85984 3.28697 4.20366C2.94315 4.54747 2.75 5.01379 2.75 5.50002V16.5C2.75 16.9863 2.94315 17.4526 3.28697 17.7964C3.63079 18.1402 4.0971 18.3334 4.58333 18.3334H11C11.4862 18.3334 11.9525 18.1402 12.2964 17.7964C12.6402 17.4526 12.8333 16.9863 12.8333 16.5V14.6667" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                    <path d="M6.41675 11H19.2501M19.2501 11L16.5001 8.25M19.2501 11L16.5001 13.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                                </svg>
                            </span>
                            <span className="ml-4 text-sm font-semibold">Log Out</span>
                        </a>
                    </div>
                </nav>
            </div>
            <div className="mx-auto lg:ml-80">
                {children}
            </div>
        </div>
    )
}
